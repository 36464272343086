import {
  takeEvery,
  takeLatest,
  fork,
  call,
  put,
  select,
  all,
} from 'redux-saga/effects'
import { delay } from 'redux-saga'
import { push } from 'react-router-redux'

import store from 'store'
import * as storage from 'utils/storage'
import handleError from 'api/apiErrors'

import * as API from 'api'
import { LOGOUT, RENDERING_DASHBOARD } from 'modules/app/constants'
import { SUBMIT_LOGIN_FORM } from 'modules/user/constants'
import { makeSelectUserID } from 'modules/user/selectors'
import { CHANGE_CURRENT_MEMBER } from 'modules/members/constants'
import {
  resetApp,
  switchMeterRequest,
  switchMeterSuccess,
  closeSwitchModal,
  getInAppMessagesSuccess,
  setHasNoMemberOrMeter,
} from 'modules/app/actions'
import {
  loginRequest,
  loginFailure,
  loginSuccess,
  getUserRequest,
  getUserFailure,
  getUserSuccess,
} from 'modules/user/actions'
import {
  getMembersRequest,
  getMembersSuccess,
  getMembersFailure,
  getEnrollmentRequest,
  getEnrollmentSuccess,
  getEnrollmentFailure,
  setCurrentMember,
  getMeterRequest,
  getMeterSuccess,
  getMeterFailure,
  getGuestsSuccess,
  createGuestSuccess,
} from 'modules/members/actions'
import { resetDeposits } from 'pages/Account/actions'
import { selectLocation } from 'modules/app/selectors'
import { makeSelectEmail } from 'modules/user/selectors'
import {
  selectFirstMember,
  selectIsGriddyGuest,
  selectEnrolledMembers,
  selectCurrentMemberID,
} from 'modules/members/selectors'
import { getCostsSaga } from 'pages/Wholesale/saga'
import { getHistorySaga } from 'pages/ElectricityUsage/saga'
import { statementSaga } from 'pages/Statement/saga'
// eslint-disable-next-line
import { getAccountSaga } from 'pages/Account/saga'
import { companyInfo } from 'config/profile'
import { changeLocale } from 'modules/language/actions'

export function* getUserSaga({ userID }) {
  yield put(getUserRequest())
  try {
    const res = yield call(API.getUser, { userID })
    yield put(getUserSuccess(res))
    return res
  } catch (error) {
    yield put(getUserFailure(error.message))
    return null
  }
}

export function* getMembersSaga({ userID }) {
  yield put(getMembersRequest())
  try {
    const res = yield call(API.getMembers, { userID })
    const members = res.Members
    yield put(getMembersSuccess(members))
    return members
  } catch (error) {
    yield put(getMembersFailure(error.message))
    throw error
  }
}

export function* getAccountID({ memberID }) {
  const account = yield call(API.getAccount, { memberID })
  const accountID = account.accountID

  return accountID
}

export function* getAccountDetails({ memberID }) {
  const account = yield call(API.getAccount, { memberID })
  const balance = {
    current: account.account_balance,
    pending: account.account_pending_balance,
    estTopOffDate: account.est_top_off_date,
  }

  const details = {
    balance,
    accountNumber: account.account_number,
    billing_address: account.billing_address,
    accountID: account.accountID,
    bill_to_name: account.bill_to_name,
    autopay : account.autopay,
    creditCardFee: account.credit_card_fee,
  }

  if (account.billing_method) details.billing_method = account.billing_method
  
  if (account.statement_delivery)
    details.statement_delivery =
      account.statement_delivery === 'email' ? true : false

  return details
}

export function* getMeterSaga({ memberID }) {
  yield put(getMeterRequest())
  try {
    const res = yield call(API.getMeter, { memberID })

    let meter = null
    if (res.meters.length) {
      meter = res.meters[0]
    }

    yield put(getMeterSuccess(meter))
    return meter
  } catch (error) {
    yield put(getMeterFailure(error.message))
    const { status } = error.response
    if (status >= 500) {
      throw error
    }
    return { error }
  }
}

export function* getEnrollmentSaga({ memberID }) {
  yield put(getEnrollmentRequest())
  try {
    const balance = yield call(getAccountDetails, { memberID })
    const meter = yield call(getMeterSaga, { memberID })
    yield put(getEnrollmentSuccess({ memberID, balance, meter }))
    return { balance, meter }
  } catch (error) {
    yield put(getEnrollmentFailure({ memberID }))
    const { status } = error.response
    if (status >= 500) {
      throw error
    }
    return { error }
  }
}

export function* getMessagesSaga({ payload: { email } }) {
  const location = yield select(selectLocation)

  if (location.pathname === '/') {
    try {
      const res = yield call(API.getInAppMessages, email)
      yield put(getInAppMessagesSuccess(res))
    } catch (error) {
      console.error(error)
    }
  }
}

function* getMessages() {
  const email = yield select(makeSelectEmail())
  yield call(getMessagesSaga, { payload: { email } })
}

export function* login({ email, password, params }) {
  try {
    let res = {}
    yield put(loginRequest())
    if (params !== undefined) {
      res = {...params}
      yield put(loginSuccess(res))
      storage.setAccessToken(res.access_token)
      storage.setRefreshToken(res.refresh_token)
    } else {
      res = yield call(API.login, { email, password })
      yield put(loginSuccess(res))
      storage.setAccessToken(res.access_token)
      storage.setRefreshToken(res.refresh_token)
    }

    const user = yield call(getUserSaga, { userID: res.userID })

    // Use user langauge preference on web
    yield put(changeLocale(user.language))

    if (user.gg_status === 'active') {
      const guests = yield call(API.getAllGuests, { userID: user.userID })
      const { Members = [] } = guests
      const guestMembers = yield all(
        Members.map(async member => {
          const { meters } = await API.getGuestMeters({
            memberID: member.memberID,
          })
          const { Members: [abandonedMember] = [] } = await API.getMembers({
            userID: user.userID,
          })
          if (abandonedMember) {
            return {
              ...member,
              meter: meters[0],
              address: abandonedMember.address,
              isGriddyGuest: true,
            }
          }
          return {
            ...member,
            meter: meters[0],
            isGriddyGuest: true,
          }
        })
      )
      yield put(getGuestsSuccess(guestMembers))
      yield put(push('/'))
      return { firstMember: guestMembers[0] }
    }

    if (user.gg_status === 'available') {
      const location = yield select(selectLocation)
      if (location.pathname.startsWith('/griddy-guest')) {
        const guest = yield call(API.createGuest, { userID: user.userID })
        const { meters } = yield call(API.getGuestMeters, {
          memberID: guest.memberID,
        })
        guest.meter = meters[0]
        const { Members: [abandonedMember] = [] } = yield call(API.getMembers, {
          userID: user.userID,
        })
        if (abandonedMember) {
          guest.address = abandonedMember.address
        }
        yield put(createGuestSuccess(guest))

        yield put(push('/'))
        return { firstMember: { ...guest, isGriddyGuest: true } }
      }
    }

    const members = yield call(getMembersSaga, { userID: res.userID })
    yield all(
      members.map(member =>
        call(getEnrollmentSaga, { memberID: member.memberID })
      )
    )

    const firstMember = yield select(selectFirstMember)
    return { user, firstMember }
  } catch (err) {
    yield put(loginFailure(err.message))
    return { err }
  }
}

export function* getLoginData({ payload }) {
  const { userID, memberID } = payload
  try {
    yield call(getUserSaga, { userID })
    const members = yield call(getMembersSaga, { userID })
    yield all(
      members.map(member =>
        call(getEnrollmentSaga, { memberID: member.memberID })
      )
    )

    // select current member
    for (const mem of members) {
      if (mem.memberID === memberID) {
        yield put(setCurrentMember(mem))
        break
      }
    }
  } catch (err) {
    console.log('getLoginData error', err)
  }
}

export function continueEnrollment() {
  window.open(companyInfo.signUpLink, '_self')
}

/**
 * handleNoMemberOrMeter indicates to the login screen that the user
 * is not activated, and resets the app in 3 seconds
 */
function* handleNoMemberOrMeter() {
  yield put(setHasNoMemberOrMeter(true))
  yield delay(3000)
  yield put(resetApp())
  storage.remove()
}

export function* loginSaga({ payload, setters }) {
  const { firstMember, err } = yield call(login, payload)

  if (err) {
    const { status } = err.response || { status: 500 }

    if (status >= 500) {
      const networkError = new Error(
        'Something went wrong while trying to log you in. Please try again later or call our Member Experience team if the problem persists.'
      )
      yield call(handleError, setters, networkError, 'email')
      return
    }

    yield call(handleError, setters, err, 'email')
    return
  }

  if (firstMember && firstMember.isGriddyGuest) {
    return
  }

  if (firstMember && firstMember.meter) {
    yield call(setCurrentMemberSaga, { payload: { member: firstMember } })
    setters.setSubmitting(false)
    yield put(push('/'))
    return
  }
  setters.setSubmitting(false)
  yield call(handleNoMemberOrMeter)
}

export function* setCurrentMemberSaga({ payload: { member, nextMeterNum } }) {
  yield put(setCurrentMember(member))
  if (!nextMeterNum) {
    
    return
  }
  
  yield put(switchMeterRequest({ nextMeterNum }))
  yield put(resetDeposits())

  const userID = yield select(makeSelectUserID())
  const {
    memberID,
    meter: { ID, settlement_point, griddy_rate_class },
  } = member
  const location = yield select(selectLocation)

  if (location.pathname === '/') {
    yield call(getCostsSaga, {
      settlementPoint: settlement_point,
    })
  }
  if (location.pathname.startsWith('/usage')) {
    yield call(getHistorySaga, { ID })
  }
  if (location.pathname.startsWith('/statement')) {
    yield call(statementSaga)
  }
  if (location.pathname.startsWith('/savings')) {
    yield call(store.dispatch.savings.getSavings, {
      memberID,
    })
  }
  if (location.pathname.startsWith('/account')) {
    yield fork(getAccountSaga, { memberID, userID })
  }

  const members = yield select(selectEnrolledMembers)
  yield put(
    switchMeterSuccess({
      meterName: `Meter ${nextMeterNum}`,
      numberOfMeters: members.length,
      griddyRateClass: griddy_rate_class,
    })
  )
  yield put(setCurrentMember(member))
  yield call(delay, 1000)

  yield put(closeSwitchModal())
}

export function* logoutSaga() {
  const isGriddyGuest = yield select(selectIsGriddyGuest)

  yield put(resetApp())
  storage.remove()

  if (isGriddyGuest) {
    yield put(push('/griddy-guest/login'))
    return
  }

  yield put(push('/login'))
}

function* updateMeter() {
  const memberID = yield select(selectCurrentMemberID)
  yield call(getEnrollmentSaga, { memberID })
}

export function* googleAnalyticsSaga(action, id) {
  let userID
  if (id) {
    userID = id
  } else {
    userID = yield select(makeSelectUserID())
  }
  try {
    while (!window.ga) {
      yield call(delay, 1000)
    }
    window.ga('set', 'dimension2', userID)
  } catch (err) {
    console.error(err)
  }
}

export default function* defaultSaga() {
  yield all([
    takeEvery(RENDERING_DASHBOARD, googleAnalyticsSaga),
    takeEvery(RENDERING_DASHBOARD, updateMeter),
    takeEvery(RENDERING_DASHBOARD, getMessages),
    takeLatest(SUBMIT_LOGIN_FORM, loginSaga),
    takeLatest(LOGOUT, logoutSaga),
    takeLatest(CHANGE_CURRENT_MEMBER, setCurrentMemberSaga),
  ])
}
