import * as storage from 'utils/storage'
import request from './request'
import { API_HOST, ITERABLE_URL, ITERABLE_KEY } from '../config'
import { url as config_urls } from 'config/profile'

export const api = async (endpoint, options, restricted) => {
  const url = `${API_HOST}${endpoint}`
  
  const headers = new Headers({
    Accept: 'application/json',
    'Content-Type': 'application/json',
  })

  if (restricted) {
    headers.append('Authorization', `Bearer ${storage.getAccessToken()}`)
  }

  try {
    return await request(url, { ...options, headers })
  } catch (err) {
    if (err.code !== 16 || err.message !== 'token has expired') {
      return Promise.reject(err)
    }

    try {
      const { access_token: accessToken } = await request(
        `${API_HOST}/users/token/refresh`,
        {
          method: 'POST',
          body: JSON.stringify({ token: storage.getRefreshToken() }),
        }
      )

      storage.setAccessToken(accessToken)
      headers.set('Authorization', `Bearer ${accessToken}`)

      return request(url, { ...options, headers })
    } catch (err) {
      // Login component logs user out and resets app
      window.location.replace('/login')
    }
  }
}

/* prettier-ignore */
api.post = (endpoint, restricted) => body => api(endpoint, { method: 'POST', body: JSON.stringify(body) }, restricted)

export const getHistory = api.post('/analytics/history', true)
export const getSavings = api.post('/analytics/savings', true)
export const getTotalSavings = api.post('/analytics/total_savings', true)
export const getUsage = api.post('/analytics/usage', true)
export const getGeneration = api.post('/analytics/generation', true)
export const getNetMeteringBankBalance = api.post(
  '/analytics/get_metering_bank_balance',
  true
)
export const getNetMeteringBankHistory = api.post(
  '/analytics/get_net_metering_bank_history',
  true
)

export const getAccount = api.post('/billing/account', true)
export const addFunds = api.post('/billing/charge_payment', true)
export const getAccountDeposits = api.post(
  '/billing/get_account_deposits',
  true
)

export const getStatements = api.post('/billing/list_statements', true)

export const getSummary = api.post('/billing/summary', true)

export const createGuest = api.post('/griddyguest/create', true)
export const updateGuest = api.post('/griddyguest/update', true)
export const getAllGuests = api.post('/griddyguest/get_all', true)
export const getGuestMeters = api.post('/griddyguest/get_meter', true)
export const getGuestHistory = api.post('/griddyguest/analytics/history', true)
export const getGuestSummary = api.post('/griddyguest/billing/summary', true)
export const getGuestSavings = api.post('/griddyguest/analytics/savings', true)
export const getGuestUsage = api.post('/griddyguest/analytics/usage', true)

export const getNow = api.post('/insights/getnow', true)

export const getMeter = api.post('/meter/get_meter', true)
export const addMeter = api.post('/meter/meters', true)
export const weatherForecast = api.post('/meter/weather_forecast', true)

export const getPriceLockRate = api.post('/meter/price_lock_rate', true)
export const setPriceLock = api.post('/meter/set_price_lock', true)
export const updateSummerAutoLock = api.post(
  '/meter/update_summer_auto_lock',
  true
)

export const meterLookup = api.post('/enrollment/meterLookup/search')
export const enrollRenewalOffer = api.post('/enrollment/update', true)

export const createMember = api.post('/members/create', true)
export const getMembers = api.post('/members/get_all', true)
export const updateMember = api.post('/members/update', true)
export const getProducts = api.post('/members/product/get', true)

export const addCard = api.post('/billing/payment_create_provider_account', true)
export const enroll = api.post('/payment/enroll', true)
export const getCards = api.post('/billing/payment_list_payment_method', true)
export const deleteCard = api.post('/billing/payment_remove_card', true)
export const updateCard = api.post('/billing/payment_set_default_card', true)
export const createChasePaymentMethod = api.post(
  '/billing/create_chase_payment_method',
  true
)
export const createICheckPaymentMethod = api.post(
  '/billing/create_icheck_payment_method',
  true
)
export const makeOneTimeAccountPayment = api.post(
  '/billing/make_onetime_payment',
  false
)
export const getAccountBalance = api.post('/billing/account/balance', true)
export const updateAutoPay = api.post('/billing/update_autopay', true)
export const updateStatementDelivery = api.post(
  '/billing/update_statement_delivery',
  true
)

export const updateAccount = api.post('/billing/update', true)

export const getPromo = api.post('/promo/get')

export const getUser = api.post('/users', true)
export const forgotPasswordResetLink = api.post('/users/password/resetlink')
export const resetPassword = api.post('/users/password/reset')
export const login = api.post('/users/signin')
export const createUser = api.post('/users/signup')
export const updateUser = api.post('/users/update', true)

export const getRenewalOffers = api.post('/members/product/renewable/get', true)

export const getInAppMessages = userEmail =>
  request(
    `${ITERABLE_URL}inApp/getMessages?email=${userEmail}&count=10&platform=iOS&SDKVersion=None&api_key=${ITERABLE_KEY}`
  )

export const getZendeskArticles = sectionID =>
  request(
    `${process.env.REACT_APP_ZENDESK_HOST
    }/api/v2/help_center/en-us/sections/${sectionID}/articles.json`
  )

export const getZendeskSections = () =>
  request(
    `${process.env.REACT_APP_ZENDESK_HOST}/${config_urls.ZENDESK_SECTIONS}`
  )
