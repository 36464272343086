import logo from 'assets/images/logos/BranchEnergyLogo-white.svg'
import LausanneFont700 from 'assets/fonts/TWKLausanne-700.ttf'
import LausanneFont350 from 'assets/fonts/TWKLausanne-350.ttf'
import FavoritMonoFont from 'assets/fonts/ABCFavoritMono-Regular.otf'
import LausanneFont700woff from 'assets/fonts/TWKLausanne-700.woff'
import LausanneFont350woff from 'assets/fonts/TWKLausanne-350.woff'
import FavoritMonoFontwoff from 'assets/fonts/ABCFavoritMono-Regular.woff'
import LausanneFont700woff2 from 'assets/fonts/TWKLausanne-700.woff2'
import LausanneFont350woff2 from 'assets/fonts/TWKLausanne-350.woff2'
import FavoritMonoFontwoff2 from 'assets/fonts/ABCFavoritMono-Regular.woff2'
import React from 'react'
import { Trans } from '@lingui/macro'

const isDev = !window.location.href.includes('app.gogriddy')

// Values from Brand Guidelines design spec
const BRAND_SPEC_GREEN = '#60D269'
const BRAND_SPEC_WHITE = '#FFFFFF'
const BRAND_SPEC_BLACK = '#000000'
const BRAND_SPEC_SLATE = '#888787'
const BRAND_SPEC_CONCRETE = '#C4C4C4'

// Values sampled from Brand Site
const BRAND_SITE_CONCRETE = '#f2f2f2'
const BRAND_SITE_LIGHT_GREEN = '#5cab62'
const BRAND_SITE_MAIN_GREEN = '#31a03a'
const BRAND_SITE_DARK_GREEN = '#2e7c34'

const base = BRAND_SPEC_BLACK
const primary = BRAND_SITE_MAIN_GREEN
const secondary = BRAND_SPEC_BLACK
const blue = BRAND_SITE_MAIN_GREEN
const gray = BRAND_SPEC_CONCRETE
const grayMedium = BRAND_SPEC_SLATE
const grey = BRAND_SPEC_SLATE
const green = BRAND_SITE_MAIN_GREEN
const greenPromo = BRAND_SITE_MAIN_GREEN
const error = '#dd2c00'
const neutral1 = BRAND_SPEC_SLATE
const neutral2 = BRAND_SPEC_SLATE
const neutral3 = BRAND_SPEC_SLATE
const neutral4 = BRAND_SPEC_SLATE
const neutral5 = BRAND_SPEC_CONCRETE
const white = BRAND_SPEC_WHITE
const footerColor = BRAND_SPEC_BLACK
const darkerPrimary = BRAND_SITE_DARK_GREEN

export const color = {
  background: BRAND_SITE_CONCRETE,
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: BRAND_SITE_MAIN_GREEN,
  link: BRAND_SITE_MAIN_GREEN,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  darkerPrimary,
  buttonColor: BRAND_SPEC_WHITE,
  buttonColorSecondary: BRAND_SPEC_WHITE,
  buttonBorder: primary,
  buttonBackground: primary,
  buttonPrimaryBackground: primary,
  secondaryHover: darkerPrimary,
  buttonSecondaryBackground: BRAND_SITE_LIGHT_GREEN,
  buttonSecondaryHover: BRAND_SPEC_GREEN,
  headerMobileNav: BRAND_SPEC_WHITE,
  costColors: {
    first: '#f38762',
    second: '#f7935c',
    third: '#6b497e',
    fourth: '#333e6b',
  },
  usageGraph: {
    isPending: primary,
    isNotPending: base,
    gradientStart: base,
    gradientEnd: primary,
    base: base,
  },
  ratePanel: '#1B0C4B',
  rateText: white,
  offerDetails: secondary,
  offerShortDescription: '#1B0C4B',
  renewalBanner: {
    text: white,
    background: primary,
  },
}

export const companyInfo = {
  portalName: 'Branch Energy',
  fullName: 'Branch Energy',
  shortName: 'Branch Energy',
  email: 'help@branchenergy.com',
  phone: '833-502-2691',
  text_number: '',
  hoursOfOperation: 'Monday - Friday 9:00 AM - 6:00 PM CST',
  signUpLink: 'https://signup.branchenergy.com/',
  defaultTimeZone: 'US/Central',
  homepageLink: 'https://www.branchenergy.com',
  mixpanelToken: '',
  faviconLink: './public/branchEnergy.ico',
  allowMakePaymentEnterNewCard: false,
  paymentProvider: "STRIPE",
  autopayScheduledText: <Trans>Autopay scheduled</Trans>,
  authOneTimePaymentText: <Trans>I authorize Branch Energy to make a one-time charge to my credit card for the amount designated above.</Trans>
}

export const logoConfig = {
  localPath: 'assets/images/logos/BranchEnergyLogo-white.svg',
  url: '',
  width: 'auto',
  // Resized size for phone header to comply with design spec spacing rules
  minimalHeight: '30px',
  height: '48px',
  useLocal: true,
  imgType: 'png',
  logo,
  wrapper: {
    minimalPadding: 15,
    padding: 24,
    backgroundColor: 'transparent',
  },
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif" // set to default baseFamily
const customFont = 'Lausanne'
const customButtonFont = 'FavoritMono'

export const font = {
  extraBold: '700',
  semiBold: '600',
  medium: '400',
  normal: '350',
  button: '500',
  baseFamily,
  customFont,
  customButtonFont,
  customButtonFontTransformations: 'uppercase',
  customFamily: ` ${customFont}, ${baseFamily}`,
  customButtonFontFamily: `${customButtonFont}, 'Courier New', Courier, monospace`,
}

export const fontFace = `
@font-face {
  font-family:  ${customFont};
  src: url('${LausanneFont700woff2}') format('woff2'),
    url('${LausanneFont700woff}') format('woff'),
    url('${LausanneFont700}') format('truetype');
  font-weight: 700;
};

@font-face {
  font-family: ${customFont};
  src: url('${LausanneFont350woff2}') format('woff2'),
    url('${LausanneFont350woff}') format('woff'),
    url('${LausanneFont350}') format('truetype');
  font-weight: 350;
};

@font-face {
  font-family:${font.customButtonFont};
  src:  url('${FavoritMonoFontwoff2}') format('woff2'),
    url('${FavoritMonoFontwoff}') format('woff'),
   url('${FavoritMonoFont}') format('opentype');
};
`

export const routes = {
  homePagePath: '/account',
  wholesale: false,
}
export const features = {
  ADD_FUNDS_PAST_DUE_BALANCE: false,
  ADD_FUNDS_CURRENT_BALANCE: false,
  ADD_FUNDS_LAST_BILL_AMOUNT: true,
  ADD_FUNDS_BALANCE_DUE: true,
  ACCOUNT_BALANCE_DUE: true,
  ACCOUNT_PAST_DUE_BALANCE: false,
  ACCOUNT_CURRENT_BALANCE: false,
  CURRENT_BALANCE_BANNER: false,
  REFER_A_FRIEND: false,
  RECHARGE_AMOUNT: false,
  ADD_METER: false,
  REACT_APP_ZENDESK_CHAT_ENABLE:
    process.env.REACT_APP_ZENDESK_CHAT_ENABLE === 'true' ? true : false,
  RENEWAL_BANNER: false,
  PLUS_BANNER: false,
  SHOW_SIGN_UP_LINK: true,
  DISABLE_BILLING_TOGGLES: true,
}

export const url = {
  SIGN_UP: isDev
    ? 'https://config.d3fbicgal063f7.amplifyapp.com'
    : 'https://signup.branchenergy.com/',
  ZENDESK_SECTIONS:
    'api/v2/help_center/en-us/categories/9902788240791-Think-Energy-FAQ-s/sections.json',
}

export const graphs = {
  usage_kwh: true,
  price_ckwh: false,
  cost_dollars: false,
}
