import logo from 'assets/images/logos/mothership.png'
import EuropaBoldFont from 'assets/fonts/Europa-Bold.ttf'
const isDev = !window.location.href.includes('app.gogriddy')

const base = '#000000'
const primary = '#000000'
const secondary = '#A7A9AC'
const blue = '#8299C2'
const gray = '#d5dee5'
const grayMedium = '#7D8D9E'
const grey = '#999999'
const green = '#7CA51B'
const greenPromo = '#8FAB36'
const error = '#dd2c00'
const neutral1 = '#022135'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'
const neutral4 = '#D5DEE5'
const neutral5 = '#F2F3F6'
const white = '#fff'
const footerColor = '#2a3048' // 001f2e
const darkerPrimary = '#e48957'

export const color = {
  background: '#f2f3f6',
  base,
  primary,
  secondary,
  blue,
  gray,
  grayMedium,
  grey,
  green,
  greenPromo,
  error,
  gradient: white,
  link: primary,
  neutral1,
  neutral2,
  neutral3,
  neutral4,
  neutral5,
  white,
  footerColor,
  darkerPrimary,
  buttonColor: '#fff',
  buttonColorSecondary: '#fff',
  buttonBorder: primary,
  buttonBackground: '#fff',
  costColors: {
    first: '#f38762',
    second: '#f7935c',
    third: '#6b497e',
    fourth: '#333e6b',
  },
  usageGraph: {
    isPending: '#9CE6EC',
    isNotPending: '#00BCD4',
    gradientStart: '#10C1D7',
    gradientEnd: '#098797',
    base: '#00BCD4',
  },
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
  renewalBanner: {
    text: 'white',
    background: primary,
  },
}

export const companyInfo = {
  portalName: 'Mothership',
  fullName: 'Mothership',
  shortName: 'Mothership',
  email: 'care@mothershipenergy.com',
  phone: '888-851-9373',
  text_number: '831-574-2994',
  hoursOfOperation: 'Monday - Friday 8:00 AM - 5:00 PM CST',
  signUpLink: 'https://localsun.mothershipenergy.com',
  defaultTimeZone: 'US/Central',
  homepageLink: 'https://mothershipenergy.com',
  mixpanelToken: '',
  faviconLink: 'https://myaccount.mothershipenergy.com/mothership.ico',
  allowMakePaymentEnterNewCard: true,
}

export const logoConfig = {
  localPath: 'assets/images/logos/mothership.png',
  url: '',
  width: 'auto',
  height: '71px',
  useLocal: true,
  imgType: 'png',
  logo,
  wrapper: {
    padding: 10,
    backgroundColor: 'transparent',
  },
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif" // set to default baseFamily
const customFont = 'Europa-Bold'

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
  font-family: ${font.customFont};
  src: url('${EuropaBoldFont}');
};

`

export const routes = {
  homePagePath: '/account',
  wholesale: false,
}
export const features = {
  ADD_FUNDS_PAST_DUE_BALANCE: false,
  ADD_FUNDS_CURRENT_BALANCE: false,
  ADD_FUNDS_LAST_BILL_AMOUNT: true,
  ADD_FUNDS_BALANCE_DUE: true,
  ACCOUNT_BALANCE_DUE: true,
  ACCOUNT_PAST_DUE_BALANCE: false,
  ACCOUNT_CURRENT_BALANCE: false,
  CURRENT_BALANCE_BANNER: false,
  REFER_A_FRIEND: false,
  RECHARGE_AMOUNT: false,
  ADD_METER: false,
  REACT_APP_ZENDESK_CHAT_ENABLE:
    process.env.REACT_APP_ZENDESK_CHAT_ENABLE === 'true' ? true : false,
  RENEWAL_BANNER: false,
  PLUS_BANNER: false,
  SHOW_SIGN_UP_LINK: true,
  DISABLE_BILLING_TOGGLES: false,
}

export const url = {
  SIGN_UP: isDev
    ? 'https://config.d3fbicgal063f7.amplifyapp.com'
    : 'https://enroll.mothership.com',
  ZENDESK_SECTIONS:
    'api/v2/help_center/en-us/categories/9386600530583-Mothership-FAQ-s/sections.json',
}

export const graphs = {
  usage_kwh: true,
  price_ckwh: false,
  cost_dollars: false,
}
