import logo from 'assets/images/logos/ShiftLogo'

const isDev = !window.location.href.includes('app.gogriddy')

const primary = '#67FCAB'
const secondary = '#25245D'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'

export const color = {
  background: '#f2f3f6',
  base: '#022135',
  primary,
  secondary,
  blue: '#5258FE',
  gray: '#d5dee5',
  grayMedium: '#7D8D9E',
  grey: '#999999',
  green: '#7CA51B',
  greenPromo: '#8FAB36',
  error: '#dd2c00',
  gradient: `linear-gradient(90deg, ${primary}, ${secondary})`,
  link: primary,
  neutral1: '#022135',
  neutral2,
  neutral3,
  neutral4: '#D5DEE5',
  neutral5: '#F2F3F6',
  white: '#fff',
  footerColor: '#001f2e',
  darkerPrimary: '#e48957',
  buttonColor: primary,
  buttonColorSecondary: secondary,
  buttonBorder: secondary,
  buttonBackground: secondary,
  costColors: {
    first: '#00b8c5',
    second: '#67fcab',
    third: '#006dac',
    fourth: '#25245d',
  },
  usageGraph: {
    isPending: '#9CE6EC',
    isNotPending: '#00BCD4',
    gradientStart: '#10C1D7',
    gradientEnd: '#098797',
    base: '#00BCD4',
  },
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
  renewalBanner: {
    text: 'white',
    background: primary,
  },
}

export const companyInfo = {
  portalName: 'Shift',
  fullName: 'Shift',
  shortName: 'Shift',
  email: 'support@shift.com',
  phone: '(800) 993-6207',
  text_number: '(281)-688-6793',
  hoursOfOperation: '(Mon - Fri 8am - 5pm CST)',
  signUpLink: 'https://enroll.abacusenergy.com',
  mixpanelToken: '',
  allowMakePaymentEnterNewCard: true,
}

export const logoConfig = {
  localPath: 'assets/images/logos/griddy.svg',
  url: '',
  width: '70px',
  height: '42px',
  useLocal: true,
  imgType: 'svg',
  logo,
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif"
const customFont = 'Girloy'

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: ${font.normal};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: ${font.medium};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-SemiBold.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-SemiBold.woff') format('woff');
  font-weight: ${font.semiBold};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff');
  font-weight: ${font.extraBold};
  font-style: normal;
}
`

export const routes = {
  homePagePath: '/wholesale',
  wholesale: true,
}

export const features = {
  ADD_FUNDS_PAST_DUE_BALANCE: false,
  ADD_FUNDS_CURRENT_BALANCE: false,
  ADD_FUNDS_LAST_BILL_AMOUNT: true,
  ADD_FUNDS_BALANCE_DUE: true,
  ACCOUNT_BALANCE_DUE: true,
  ACCOUNT_PAST_DUE_BALANCE: false,
  ACCOUNT_CURRENT_BALANCE: false,
  CURRENT_BALANCE_BANNER: true,
  REFER_A_FRIEND: true,
  REACT_APP_ZENDESK_CHAT_ENABLE:
    process.env.REACT_APP_ZENDESK_CHAT_ENABLE === 'true' ? true : false,
  RENEWAL_BANNER: false,
  PLUS_BANNER: false,
  SHOW_SIGN_UP_LINK: true,
  DISABLE_BILLING_TOGGLES: false,
}

export const url = {
  SIGN_UP: isDev
    ? 'https://config.d3fbicgal063f7.amplifyapp.com'
    : 'https://enroll.abacus-energy.com',
  ZENDESK_SECTIONS:
    'api/v2/help_center/en-us/categories/4412571127575-Abacus-Energy/sections.json',
}

export const graphs = {
  usage_kwh: true,
  price_ckwh: false,
  cost_dollars: true,
}
