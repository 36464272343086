import logo from 'assets/images/logos/GriddyLogo'

const isDev = !window.location.href.includes('app.gogriddy')

const primary = '#ed0874'
const secondary = '#9db1c6'
const base = '#022135'
const neutral2 = '#7D8D9E'
const neutral3 = '#9DB1C6'

export const color = {
  background: '#f2f3f6',
  base,
  primary,
  secondary,
  blue: '#00BCD4',
  gray: '#d5dee5',
  grayMedium: '#7D8D9E',
  grey: '#999999',
  green: '#7CA51B',
  greenPromo: '#8FAB36',
  error: '#dd2c00',
  gradient: `linear-gradient(90deg, ${primary}, #f47725)`,
  link: primary,
  neutral1: '#022135',
  neutral2,
  neutral3,
  neutral4: '#D5DEE5',
  neutral5: '#F2F3F6',
  white: '#fff',
  footerColor: '#001f2e',
  darkerPrimary: '#e48957',
  buttonColor: primary,
  buttonColorSecondary: '#fff',
  buttonBorder: primary,
  buttonBackground: '#fff',
  costColors: {
    first: '#669A02',
    second: '#96BE2D',
    third: '#EF216B',
    fourth: '#960227',
  },
  usageGraph: {
    isPending: '#9CE6EC',
    isNotPending: '#00BCD4',
    gradientStart: '#10C1D7',
    gradientEnd: '#098797',
    base: '#00BCD4',
  },
  textColor: base,
  ratePanel: secondary,
  rateText: primary,
  offerDetails: neutral3,
  offerShortDescription: neutral2,
}

export const companyInfo = {
  portalName: 'Griddy',
  fullName: 'Griddy Energy',
  shortName: 'Griddy',
  email: 'support@griddyx.com',
  phone: '(800) 993-6207',
  text_number: '(281)-688-6793',
  hoursOfOperation: '(Mon - Fri 8am - 5pm CST)',
  signUpLink: 'https://enroll.abacusenergy.com',
  mixpanelToken: '',
  allowMakePaymentEnterNewCard: true,
  paymentProvider: "STRIPE",
}

export const logoConfig = {
  localPath: 'assets/images/logos/griddy.svg',
  url: '',
  width: '70px',
  height: '42px',
  useLocal: true,
  imgType: 'svg',
  logo,
}

const baseFamily = "'Helvetica Neue', Helvetica, Arial, sans-serif"
const customFont = 'Girloy'

export const font = {
  extraBold: '800',
  semiBold: '600',
  medium: '500',
  normal: '400',
  baseFamily,
  customFont,
  customFamily: `${customFont}, ${baseFamily}`,
}

export const fontFace = `
@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-Regular.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-Regular.woff') format('woff');
  font-weight: ${font.normal};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-Medium.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-Medium.woff') format('woff');
  font-weight: ${font.medium};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-SemiBold.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-SemiBold.woff') format('woff');
  font-weight: ${font.semiBold};
  font-style: normal;
}

@font-face {
  font-family: ${font.customFont};
  src:
    url('/fonts/gilroy/Gilroy-ExtraBold.woff2') format('woff2'),
    url('/fonts/gilroy/Gilroy-ExtraBold.woff') format('woff');
  font-weight: ${font.extraBold};
  font-style: normal;
}
`

export const routes = {
  homePagePath: '/wholesale',
  wholesale: true,
}

export const features = {
  CURRENT_BALANCE: true,
  REFER_A_FRIEND: true,
  REACT_APP_ZENDESK_CHAT_ENABLE:
    process.env.REACT_APP_ZENDESK_CHAT_ENABLE === 'true' ? true : false,
  SHOW_SIGN_UP_LINK: true,
  DISABLE_BILLING_TOGGLES: false,
}

export const url = {
  SIGN_UP: isDev
    ? 'https://www.enroll.development.griddyx.com'
    : 'https://enroll.griddy.com',
  ZENDESK_SECTIONS:
    'api/v2/help_center/en-us/categories/204461828-FAQ/sections.json',
}

export const graphs = {
  usage_kwh: true,
  price_ckwh: true,
  cost_dollars: true,
}
